<template>
    <section class="privacy-policy">
      <h1>Política de Privacidade</h1>
      <br>
      <br>
      <h3>Quais dados coletamos</h3>
      <p>Coletamos os seus dados conforme o seu acesso, uso do site e serviços oferecidos. Isso pode incluir geolocalização, endereço IP, e informações sobre seu dispositivo de acesso.</p>
      <br>
      <h3>Como usamos os dados</h3>
      <p>Usamos os dados para aprimorar sua experiência, personalizar conteúdo, realizar estatísticas, e cumprir obrigações legais, entre outros usos descritos nesta política.</p>
      <br>
      <h3>Uso de serviços de terceiros</h3>
      <p>Utilizamos serviços como Google Analytics para coleta e processamento de dados, de acordo com os termos e políticas dos fornecedores.</p>
  
      <p>Para mais informações ou para gerenciar seus dados, entre em contato conosco.</p>
    </section>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy",
  };
  </script>
  
  <style scoped>
  .privacy-policy {
    padding: 100px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  h1, h2 {
    color: #333;
  }
  p {
    margin-bottom: 10px;
  }


  </style>
  