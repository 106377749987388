<template>
  <v-container>
    <v-row v-if="news.length > 0">
      <!-- Coluna Esquerda: Notícia em Destaque -->
      <v-col cols="12" md="7">
        <div v-for="(item, index) in news.slice(0, 1)" :key="index">
          <div style="width: 100%; text-align: center">
            <img :src="item.imageUrl" height="200px" />
          </div>
          <br>
          <small>{{ formatDate(item.date) }} | {{ item.source }}</small>
          <h1 @click="openLink(item.url)" style="cursor: pointer;">{{ item.title }}</h1>
          <p>{{ item.content }}</p>
        </div>
      </v-col>

      <!-- Coluna Direita: notícias recentes -->
      <v-col cols="12" md="5">
        <p>highlights</p>
        <hr style="border: 2px solid #f8a602; margin-bottom:20px">
        <div v-for="(item, index) in news.slice(1, 4)" :key="index">
          <small>{{ formatDate(item.date) }}</small>
          <h4 @click="openLink(item.url)">{{ item.title }}</h4>
          <!-- Conteúdo com elipse e link para acessar -->
          <p class="desc elipse">{{ item.content }}</p>
          <a class="acessar" :href="item.url" target="_blank">acessar {{ item.source }}</a>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="loading">
      <v-col cols="12" md="7">
        <v-skeleton-loader
          type="image, article"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="5">
        <p>highlights</p>
        <hr style="border: 2px solid #f8a602; margin-bottom:20px">
        <v-skeleton-loader
          type="text"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="text"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="text"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <p v-if="!loading && news.length === 0">Nenhuma notícia disponível no momento.</p>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { collection, query, orderBy, where, limit, getDocs } from 'firebase/firestore';
import { db } from '@/firebase'; // Certifique-se de que o caminho esteja correto

export default {
  name: "FeaturedNews",
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    },
    formatDate(date) {
      if (!date) return ''; // Verifica se a data está indefinida ou nula

      // Se for um timestamp do Firebase, converte para Date
      let publishedDate;
      if (date.toDate) {
        publishedDate = date.toDate(); // Converte usando o método toDate()
      } else {
        publishedDate = new Date(date);
      }

      if (isNaN(publishedDate)) {
        // Se a data não for válida, retorna uma string padrão ou trata de outra maneira
        return 'Data inválida';
      }

      const now = new Date();
      const timeDiff = Math.abs(now - publishedDate);
      const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const diffWeeks = Math.floor(diffDays / 7);

      if (diffDays === 1) {
        return '1 dia atrás';
      } else if (diffDays < 7) {
        return `${diffDays} dias atrás`;
      } else if (diffWeeks === 1) {
        return '1 semana atrás';
      } else if (diffWeeks > 1) {
        return `${diffWeeks} semanas atrás`;
      } else {
        // Formata a data como dd/mm/yyyy
        return `${publishedDate.getDate().toString().padStart(2, '0')}/${(publishedDate.getMonth() + 1).toString().padStart(2, '0')}/${publishedDate.getFullYear()}`;
      }
    }
  },
  setup() {
    const loading = ref(false);
    const news = ref([]);

    const loadFeaturedNews = async () => {
      loading.value = true;
      try {
        const q = query(
          collection(db, 'news'),
          where('highlight', '==', true),
          where('publish', '==', true),
          orderBy('date', 'desc'),
          limit(4)
        );
        const querySnapshot = await getDocs(q);
        // Filtrando as notícias em destaque
        news.value = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Erro ao carregar as notícias:", error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      loadFeaturedNews();
    });

    return {
      loading,
      news,
    };
  }
};
</script>

<style scoped>
h1 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

h4 {
  cursor: pointer;
}

.desc {
  font-size: 0.8rem;
  margin-bottom: 0px !important;
}

.acessar {
  font-size: 0.8rem;
  margin-bottom: 30px;
  display: inline-block; /* Para garantir que a margem tenha efeito */
}

/* Classe para elipse */
.elipse {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
}
</style>
