<template>
  <v-container>
    <v-row v-for="item in displayedNews" :key="item.id">
      <v-col cols="3" class="d-none d-md-flex">
        <img :src="item.imageUrl" width="100%" style="height: fit-content;" />
      </v-col>
      <v-col cols="12" md="9">
        <small>{{ formatDate(item.date) }}</small>
        <h3 @click="openInNewTab(item.url)" style="margin-bottom: 10px; cursor: pointer;">{{ item.title }}</h3>
        <p>{{ item.content }} <a :href="item.url" target="_blank">acessar {{ item.source }}</a></p>
      </v-col>
      <v-col cols="12"><hr></v-col>
    </v-row>

    <v-row v-if="loading">
      <v-col cols="3" class="d-none d-md-flex">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="9">
        <v-skeleton-loader type="text"></v-skeleton-loader>
        <v-skeleton-loader type="text, text, text"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="!loading && canLoadMore">
      <v-col cols="12" class="text-center">
        <v-btn @click="loadMoreNews" color="#f8a600">Carregar mais notícias</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from 'vue';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '@/firebase'; // Certifique-se de que o caminho esteja correto

export default {
  name: "NewsList",
  setup() {
    const loading = ref(false);
    const news = ref([]);
    const displayedNews = ref([]);
    const itemsToShow = ref(10); // Número inicial de notícias exibidas
    const canLoadMore = ref(false);

    const openInNewTab = (url) => {
      if (url) {
        window.open(url, '_blank');
      }
    };

    const loadNews = async () => {
      loading.value = true;
      try {
        let q = query(
          collection(db, 'news'),
          where('highlight', '==', false),
          where('publish', '==', true),
          orderBy('date', 'desc')
        );

        const querySnapshot = await getDocs(q);
        news.value = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        displayedNews.value = news.value.slice(0, itemsToShow.value);
        canLoadMore.value = news.value.length > displayedNews.value.length;
      } catch (error) {
        console.error("Erro ao carregar notícias:", error);
      } finally {
        loading.value = false;
      }
    };

    const loadMoreNews = () => {
      const newLimit = displayedNews.value.length + 10;
      displayedNews.value = news.value.slice(0, newLimit);
      canLoadMore.value = news.value.length > displayedNews.value.length;
    };

    // Função para formatar a data
    const formatDate = (date) => {
      if (!date) return ''; // Verifica se a data está indefinida ou nula

      // Se for um timestamp do Firebase, converte para Date
      let publishedDate;
      if (date.toDate) {
        publishedDate = date.toDate(); // Converte usando o método toDate()
      } else {
        publishedDate = new Date(date);
      }

      if (isNaN(publishedDate)) {
        // Se a data não for válida, retorna uma string padrão ou trata de outra maneira
        return 'Data inválida';
      }

      const now = new Date();
      const timeDiff = Math.abs(now - publishedDate);
      const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const diffWeeks = Math.floor(diffDays / 7);

      if (diffDays === 1) {
        return '1 dia atrás';
      } else if (diffDays < 7) {
        return `${diffDays} dias atrás`;
      } else if (diffWeeks === 1) {
        return '1 semana atrás';
      } else if (diffWeeks > 1) {
        return `${diffWeeks} semanas atrás`;
      } else {
        // Formata a data como dd/mm/yyyy
        return `${publishedDate.getDate().toString().padStart(2, '0')}/${(publishedDate.getMonth() + 1).toString().padStart(2, '0')}/${publishedDate.getFullYear()}`;
      }
    };


    // Carrega as notícias ao montar o componente
    loadNews();

    return {
      loading,
      news,
      displayedNews,
      loadNews,
      openInNewTab,
      loadMoreNews,
      canLoadMore,
      formatDate // Adiciona a função ao retorno para que esteja disponível no template
    };
  }
};
</script>

<style scoped>
/* Adicione seu estilo customizado aqui, se necessário */
</style>
